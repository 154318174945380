window.initializeCarousel = function({
    container,
    container2 = null,
    bulletsContainer = container,
    bulletsContainer2 = container2,
    hasInterval = false,
    intervalTime = 10000,
    slidesPerView = 1,
    arrowsContainer = null
}) {
    let nSlides = container.find('.carousel-slide').length;
    let slideIndex = 0;

    container.find('.carousel-slides').css({
        "width": (nSlides * 100 / slidesPerView) + "%",
        "display": "flex"
    });

    container.find('.carousel-slide').css({
        "flex-basis": (100 / nSlides * slidesPerView) + "%"
    });

    if (container2 !== null) {
        container2.find('.carousel-slides').css({
            "width": (nSlides * 100 / slidesPerView) + "%",
            "display": "flex"
        });

        container2.find('.carousel-slide').css({
            "flex-basis": (100 / nSlides * slidesPerView) + "%"
        });
    }

    let slideWidth = container.find('.carousel-slide').width();

    function goToSlide(index) {
        container.find('.carousel-slides').css('transform', 'translateX(-' + (slideWidth * index) + 'px)');
        container.find('.carousel-slide').removeClass('active');
        container.find('.carousel-slide').eq(index).addClass('active');
        bulletsContainer.find('.bullet').removeClass('active');
        bulletsContainer.find('.bullet').eq(index).addClass('active');

        if (container2 !== null) {
            container2.find('.carousel-slides').css('transform', 'translateX(-' + (slideWidth * index) + 'px)');
            container2.find('.carousel-slide').removeClass('active');
            container2.find('.carousel-slide').eq(index).addClass('active');
        }
    }

    let detectSwipe = function(element, onLeftSwipe, onRightSwipe) {
        let touchStartX = 0;
        let touchEndX = 0;

        element.addEventListener('touchstart', function(event) {
            touchStartX = event.changedTouches[0].screenX;
        }, false);

        element.addEventListener('touchend', function(event) {
            touchEndX = event.changedTouches[0].screenX;

            if (touchEndX < touchStartX) {
                onLeftSwipe();
            }

            if (touchEndX > touchStartX) {
                onRightSwipe();
            }
        }, false);
    }

    detectSwipe(container[0],
        function() {
            moveRight();
        }, function() {
            moveLeft();
        }
    );

    if (container2 !== null) {
        detectSwipe(container2[0],
            function() {
                moveRight();
            }, function() {
                moveLeft();
            }
        );
    }

    function moveLeft() {
        slideIndex--;
        if (slideIndex < 0) {
            slideIndex = nSlides - 1;
        }
        goToSlide(slideIndex);
    }

    function moveRight() {
        slideIndex++;
        if (slideIndex >= nSlides) {
            slideIndex = 0;
        }
        goToSlide(slideIndex);
    }

    bulletsContainer.find('.bullet').click(function() {
        slideIndex = $(this).index();
        goToSlide(slideIndex);
    });

    $(window).on("resize", function() {
        slideWidth = container.find('.carousel-slide').outerWidth() / slidesPerView;
        goToSlide(slideIndex);
    });

    if (hasInterval) {
        let slideInterval = setInterval(moveRight, intervalTime);

        // $('.carousel-hover-stop-interval').on('mouseenter touchstart', function() {
        //     clearInterval(slideInterval);
        // }).on('mouseleave touchend', function() {
        //     slideInterval = setInterval(moveRight, intervalTime);
        // });
    }

    if (arrowsContainer) {
        arrowsContainer.find('.arrow-left').click(function() {
            moveLeft();
        });

        arrowsContainer.find('.arrow-right').click(function() {
            moveRight();
        });
    }
}
